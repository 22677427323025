import React from "react"
import get from "lodash/get"
import { useEffect, useState } from "react"
import { Slider } from "baseui/slider"
import { Search } from "baseui/icon"
import { Button } from "baseui/button"
import CloudApi from "cloudApi/api"
import { Block } from "baseui/block"
import { StatefulSelect } from "baseui/select"
import { Input } from "baseui/input"
import { Label2, Caption2 } from "baseui/typography"
import { FacultySelect } from "components/molecules"
import withJob from "hoc/withJob"

const JobFilter = ({ jobDispatch, jobState, setParams, setActiveJobIndex }) => {
  const { prices } = jobState
  const [hideFilterMenu, setHideFilterMenu] = useState(true)
  const [priceValue, setPriceValue] = useState(null)
  const [faculty, setFaculty] = useState([])
  const [jobTitle, setJobTitle] = useState(null)
  const [jobType, setJobType] = useState([])
  const [openFilterFirstTime, setOpenFilterFirstTime] = useState(true)

  useEffect(() => {
    if (prices) {
      setPriceValue([prices.min, prices.max])
    }
  }, [prices])

  const getWindowDimensions = () => {
    const windowGlobal = typeof window !== "undefined" && window
    const { innerWidth: width, innerHeight: height } = windowGlobal
    return {
      width,
      height,
    }
  }

  const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    )

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions())
      }

      window.addEventListener("resize", handleResize)
      return () => window.removeEventListener("resize", handleResize)
    }, [])

    return windowDimensions
  }

  const getJobsByFilter = () => {
    let param = `${jobTitle ? `title=${jobTitle}&` : ""}${
      faculty.length > 0 ? `faculty=${faculty[0].faculty_id}&` : ""
    }price_start=${priceValue[0]}&price_stop=${priceValue[1]}`
    if (jobType.length > 0) {
      let tagsParam = ""
      jobType.forEach(tag =>
        tagsParam = tagsParam + `&tags=${tag.id}`
      )
      param = param.concat(tagsParam)
    }

    if (jobType.length > 0) {
      let tagsParam = ""
      jobType.forEach(tag =>
        tagsParam = tagsParam + `&tags=${tag.id}`
      )
      param = param.concat(tagsParam)
    }
    setParams(param)
    setActiveJobIndex(0)
    return CloudApi.getJobListsByFilter({ filterData: param, offset: 0 })
      .then(jobs => {
        jobDispatch({ type: "FETCH_JOBS_DATA", payload: jobs, reset: true })
      })
      .catch(err => {
        if (err.status === 404 && err.statusText === "Not Found")
          jobDispatch({ type: "NO_FILTER_JOB", payload: [] })
      })
  }

  const { width } = useWindowDimensions()
  const { tags } = jobState
  return (
    <Block>
      <Block
        display={["block", "block", "none", "none"]}
        padding={"20px"}
        justifyContent={"space-between"}
        overrides={{ Block: { style: { borderBottom: "1px solid #ebebeb" } } }}
      >
        <Label2>งานที่เปิดรับทั้งหมด</Label2>
        <Label2
          color={"#ff7c1b"}
          onClick={() => {
            setHideFilterMenu(!hideFilterMenu)
            if (openFilterFirstTime) {
              setPriceValue([get(prices, "min", 50), get(prices, "max")])
            }
            setOpenFilterFirstTime(false)
          }}
        >
          ตัวกรอง
        </Label2>
      </Block>
      <Block
        padding={["15px", "15px", "20px", "20px"]}
        backgroundColor={"#fcfcfc"}
        display={hideFilterMenu && width < 650 ? "none" : "block"}
        overrides={{
          Block: {
            style: {
              display: "block",
              "@media (max-width: 640px)": {
                display: hideFilterMenu && width < 650 ? "none" : "block",
              },
            },
          },
        }}
      >
        <Block
          display={["flex"]}
          justifyContent={"space-between"}
          flexDirection={["column", "column", "row", "row"]}
        >
          <Block width={["100%", "100%", "30%", "30%"]}>
            <Input
              startEnhancer={<Search size="18px" />}
              placeholder="ชื่องาน"
              value={jobTitle}
              onChange={event => setJobTitle(event.currentTarget.value)}
            />
          </Block>
          <Block
            width={["100%", "100%", "30%", "30%"]}
            marginTop={["20px", "20px", "0px", "0px"]}
          >
            <FacultySelect onChange={setFaculty} value={faculty} />
          </Block>
          <Block
            width={["100%", "100%", "30%", "30%"]}
            marginTop={["20px", "20px", "0px", "0px"]}
          >
            {tags.length > 0 && (
              <StatefulSelect
                multi
                options={tags}
                placeholder="ประเภทงาน"
                labelKey="name"
                valueKey="id"
                onChange={event => setJobType(event.value)}
                value={jobType}
              />
            )}
          </Block>
        </Block>
        <Block
          display={"flex"}
          justifyContent={[
            "center",
            "center",
            "space-between",
            "space-between",
          ]}
          alignItems={"center"}
          flexDirection={["column", "column", "row", "row"]}
          marginTop={["20px", "20px", "15px", "15px"]}
        >
          <Block
            display={"flex"}
            alignItems={"center"}
            justifyContent={[
              "space-between",
              "space-between",
              "flex-start",
              "flex-start",
            ]}
            width={["100%", "100%", "auto", "auto"]}
          >
            <Block>
              <Label2>ค่าตอบแทน</Label2>
              <Caption2>(บาท)</Caption2>
            </Block>
            <Block width={["70%", "70%", "320px", "320px"]}>
              {prices && priceValue && (
                <Slider
                  value={priceValue}
                  min={0}
                  max={get(prices, "max", 500)}
                  step={10}
                  onChange={({ value }) => value && setPriceValue(value)}
                  overrides={{
                    Thumb: {
                      style: { zIndex: "0 !important" },
                    },
                  }}
                />
              )}
            </Block>
          </Block>
          <Button
            onClick={() => getJobsByFilter()}
            styleInMobile={{ marginTop: "20px" }}
          >
            ค้นหา
          </Button>
        </Block>
      </Block>
    </Block>
  )
}

export default withJob(JobFilter)
