import React from "react"
import { Button, KIND } from "baseui/button"
import { Label2 } from "baseui/typography"
import { Modal, ModalHeader, ModalBody } from "baseui/modal"
import { Block } from "baseui/block"
import { useStyletron } from "baseui"
import Check from "baseui/icon/check"
import Delete from "baseui/icon/delete"
import StudentProfile from "../components/molecules/StudentProfile"
import { patchAppliedJob } from "../actions/job"

const ListItemAction = ({ jobsTrackDispatch, jobId, userId, status }) => {
  return (
    <Block display={"flex"} flexDirection={"column"}>
      {status === "pending" && (
        <>
          <Button
            overrides={{
              BaseButton: {
                style: ({ $theme }) => {
                  return {
                    marginBottom: $theme.sizing.scale400,
                    border: `1.5px solid ${$theme.colors.primary}`,
                    background: "white",
                  }
                },
              },
            }}
            kind={KIND.secondary}
            onClick={() => {
              patchAppliedJob(jobsTrackDispatch, jobId, userId, "apply")
            }}
          >
            เลือก
          </Button>
          <Button
            overrides={{
              BaseButton: {
                style: ({ $theme }) => {
                  return {
                    textDecoration: "underline",
                    padding: $theme.sizing.scale0,
                  }
                },
              },
            }}
            onClick={() =>
              patchAppliedJob(jobsTrackDispatch, jobId, userId, "reject")
            }
            kind={KIND.minimal}
            size={"compact"}
          >
            x ไม่ผ่านเกณฑ์
          </Button>
        </>
      )}
      {status === "confirm" && (
        <>
          <Button kind={KIND.primary} startEnhancer={<Check size={24} />}>
            รับแล้ว
          </Button>
        </>
      )}
      {status === "reject" && (
        <>
          <Button kind={KIND.primary} startEnhancer={<Delete size={24} />}>
            ไม่ผ่าน
          </Button>
        </>
      )}
    </Block>
  )
}

const AppliedListItem = props => {
  const [, theme] = useStyletron()
  return (
    <Block
      display={"flex"}
      flexDirection={["column", "column", "row", "row"]}
      alignItems={"center"}
      padding={[
        theme.sizing.scale300,
        theme.sizing.scale300,
        theme.sizing.scale900,
        theme.sizing.scale900,
      ]}
      marginBottom={theme.sizing.scale600}
      overrides={{
        Block: {
          style: {
            border: "1px solid #eaeaea",
            borderRadius: "8px",
          },
        },
      }}
    >
      <StudentProfile student={props.student} noEditNumber />
      <ListItemAction
        userId={props.student.user_id}
        jobId={props.jobId}
        status={props.student.status}
        jobsTrackDispatch={props.jobsTrackDispatch}
      />
    </Block>
  )
}

const JobAppliedModal = props => {
  const { usersInJobsTrack, jobsTrackDispatch } = props
  return (
    <Modal
      isOpen={props.job !== null}
      size={"full"}
      onClose={() => props.onClose()}
    >
      <ModalHeader>
        {props.job.title}
        <Label2>
          ผู้สมัคร ( {props.job.quota_count} / {props.job.quota} )
        </Label2>
      </ModalHeader>
      <ModalBody>
        {usersInJobsTrack.length &&
          usersInJobsTrack
            .find(jobTrack => jobTrack.id === props.job.id)
            .users.sort(data => data.status)
            .map((data, index) => (
              <AppliedListItem
                jobId={props.job.id}
                index={index}
                student={data}
                jobsTrackDispatch={jobsTrackDispatch}
              />
            ))}
      </ModalBody>
    </Modal>
  )
}

export default JobAppliedModal
