import React from 'react'
import { Block } from 'baseui/block'
import { format } from 'date-fns'
import { Tag } from 'baseui/tag'
import { Button, KIND } from 'baseui/button'
import { Label2, Paragraph2, H5 } from 'baseui/typography'
import { StyledTable, StyledHeadCell, StyledBodyCell } from 'baseui/table-grid'
import { Pagination } from 'baseui/pagination'
import { navigate } from 'gatsby'
import { FolderOff32 } from '@carbon/icons-react'


const mapJobStatusWithTag = (status, isPublish) => {
  let jobStatus = {
    color: '#FFB809',
    status: 'ยังไม่ประกาศ'
  }
  if (isPublish) {
    if (status === 'open') {
      jobStatus = {
        color: '#08a35a',
        status: 'เปิดรับอยู่'
      }
    } else if (status === 'closed') {
      jobStatus = {
        color: '#E54936',
        status: 'ปิดรับแล้ว'
      }
    }
  }
  return <Tag
    variant="outlined"
    closeable={false}
    overrides={{
      Root: {
        style: {
          borderColor: jobStatus.color,
        },
      },
      Text: {
        style: {
          color: jobStatus.color,
        },
      },
    }}
  >
    {jobStatus.status}
  </Tag>
}

const Table = ({
                 total,
                 jobs,
                 updateData,
                 getUsersInJobsTrack,
                 setSelectedJob,
                 isPublic,
               }) => {
  const [ page, setPage ] = React.useState(1)
  const [ limit ] = React.useState(8)

  const handlePageChange = nextPage => {
    if (nextPage < 1) {
      return
    }
    if (nextPage > Math.ceil(total / limit)) {
      return
    }
    if (
      nextPage > page &&
      nextPage * limit > jobs.length &&
      jobs.length < total
    ) {
      setPage(nextPage)
      updateData(nextPage * limit - jobs.length)
    } else {
      setPage(nextPage)
    }
  }

  const window = () => {
    const min = (page - 1) * limit
    return jobs.slice(min, min + limit)
  }

  return (
    <Block>
      <StyledTable $gridTemplateColumns="auto auto auto auto auto auto">
        <StyledHeadCell>
          <Label2 margin={'auto'}>วันที่สร้าง</Label2>
        </StyledHeadCell>
        <StyledHeadCell>
          <Label2 margin={'auto'}>ชื่องาน</Label2>
        </StyledHeadCell>
        <StyledHeadCell
          style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px' }}
        >
          <Label2 margin={'auto'}>ประเภท</Label2>
        </StyledHeadCell>
        <StyledHeadCell>
          <Label2 margin={'auto'}>สมัครแล้ว</Label2>
        </StyledHeadCell>
        <StyledHeadCell>
          <Label2 margin={'auto'}>รับแล้ว</Label2>
        </StyledHeadCell>
        <StyledHeadCell>
          <Label2 margin={'auto'}>สถานะ</Label2>
        </StyledHeadCell>
        {jobs.length > 0 ?
          window().map(job => (
            <React.Fragment key={job.id}>
              <StyledBodyCell>
                {format(new Date(job.created_at), 'dd MMM yyyy')}
              </StyledBodyCell>
              <StyledBodyCell>{job.title}</StyledBodyCell>
              <StyledBodyCell>
                <Block>
                  {job.tags &&
                  job.tags.map((tag, index) => (
                    <Tag
                      key={index}
                      closeable={false}
                      variant={'outlined'}
                      kind="primary"
                    >
                      {tag}
                    </Tag>
                  ))}
                </Block>
              </StyledBodyCell>
              <StyledBodyCell>
                <Block display={'flex'} justifyContent={'space-between'}>
                  {job.applied.length} คน
                  {job.status === 'open' && job.applied.length > 0 && (
                    <Button
                      kind={KIND.secondary}
                      size={'compact'}
                      onClick={() => {
                        getUsersInJobsTrack(job.id).then(() => {
                          setSelectedJob(job)
                        })
                      }}
                    >
                      เลือกนักศึกษา
                    </Button>
                  )}
                </Block>
              </StyledBodyCell>
              <StyledBodyCell>
                ({job.quota_count}/{job.quota})
              </StyledBodyCell>
              <StyledBodyCell>
                <Block
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {mapJobStatusWithTag(job.status, job.is_publish)}
                  {job.status !== 'closed' && (
                    <Paragraph2
                      onClick={() =>
                        navigate(
                          `/job-form?jobId=${job.id}&public=${
                            isPublic ? 'true' : 'false'
                          }`
                        )
                      }
                      style={{
                        marginTop: 0,
                        marginBottom: 0,
                        cursor: 'pointer',
                        color: '#fd7c2e',
                        textDecoration: 'underline',
                      }}
                    >
                      แก้ไข
                    </Paragraph2>
                  )}
                </Block>
              </StyledBodyCell>
            </React.Fragment>
          ))
          :
          <Block gridColumn="1/7" $style={{ textAlign: 'center' }}>
            <FolderOff32 style={{ width: 60, height: 60, marginTop: 40, fill: '#545454' }}/>
            <H5 marginTop={'8px'}
                color={'#545454'}>{isPublic ? 'ไม่มีงานที่ประกาศแล้ว' : 'ไม่มีงานที่ยังไม่ประกาศ'}</H5>
          </Block>
        }
      </StyledTable>
      {jobs.length > 0 &&
      <Pagination
        currentPage={page}
        numPages={Math.ceil(total / limit)}
        onPageChange={({ nextPage }) => handlePageChange(nextPage)}
        overrides={{
          Root: {
            style: { justifyContent: 'flex-end', marginTop: '25px' }
          }
        }}
      />
      }
    </Block>
  )
}

export default Table
