import React from "react"
import CloudApi from "cloudApi/api"
import { Block } from "baseui/block"
import { Paragraph1, Paragraph2 } from "baseui/typography"
import ProfileAvatar from "../../atoms/ProfileAvatar"
import { Button } from "baseui/button"
import PhoneModal from "components/molecules/PhoneModal"
import { getProfile } from "../../../actions/auth"
import withUser from "hoc/withUser"

const StudentProfile = ({
  column,
  student,
  userDispatch,
  noEditNumber = false,
}) => {
  const {
    img,
    email,
    faculty_th: faculty,
    grade,
    dept_th: department,
    curr2_th: branch,
    phone,
    surname_th,
    name_th,
    line_id: lineId,
  } = student
  const fullName = `${name_th} ${surname_th}`
  const [showPhoneModal, setShowPhoneModal] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  return (
    <Block
      flex={1}
      display={"flex"}
      flexDirection={column ? "column" : ["column", "column", "row", "row"]}
      justifyContent={"space-around"}
      alignItems={"center"}
      style={{
        textAlign: "center",
      }}
    >
      <Block
        display={"flex"}
        flexDirection={column ? "column" : ["column", "column", "row", "row"]}
        flex={1}
        alignItems={"center"}
      >
        <ProfileAvatar
          name={fullName !== " " ? fullName : "-"}
          size={"80px"}
          src={img}
        />
        <Block flex={1}>
          <Paragraph1>{fullName !== " " ? fullName : "-"}</Paragraph1>
          <Paragraph2>{email}</Paragraph2>
          <Paragraph2>
            คณะ {faculty !== "" ? faculty : "-"} {grade !== "" ? grade : "-"}
          </Paragraph2>
        </Block>
      </Block>
      <Block flex={1}>
        <Paragraph2>ภาควิชา: {department !== "" ? department : "-"}</Paragraph2>
        <Paragraph2>สาขาวิชา: {branch !== "" ? branch : "-"}</Paragraph2>
      </Block>
      <Block flex={1}>
        <Paragraph2>เบอร์โทร: {phone || "-"}</Paragraph2>
        <Paragraph2>Line ID: {lineId || "-"}</Paragraph2>
        {!noEditNumber && (
          <Button onClick={() => setShowPhoneModal(true)}>
            {phone ? "แก้ไข" : "เพิ่ม"}ข้อมูลติดต่อ
          </Button>
        )}
      </Block>
      <PhoneModal
        toEditData={{phone, lineId}}
        showPhoneModal={showPhoneModal}
        onClose={() => setShowPhoneModal(false)}
        loading={loading}
        submit={(phoneData, lineIdData) => {
          setLoading(true)
          return CloudApi.updatePhoneUser(
            student.id,
            phoneData,
            lineIdData
          ).then(() => {
            setShowPhoneModal(false)
            setLoading(false)
            getProfile(userDispatch)
          })
        }}
      />
    </Block>
  )
}

export default withUser(StudentProfile)
