import React, { useEffect } from 'react'
import get from 'lodash/get'
import { Block } from 'baseui/block'
import { Button } from 'baseui/button'
import { H5 } from 'baseui/typography'
import { Tabs, Tab } from 'baseui/tabs'
import { Header } from '../components/molecules'
import withJobTrack from '../hoc/withJobTrack'

import { navigate } from 'gatsby'
import JobAppliedModal from '../components/JobAppliedModal'
import checkPermission from '../hoc/checkPermission'
import withUser from '../hoc/withUser'
import {
  fetchJobTrackData,
  fetchUserInJob,
  fetchJobTrackUnPublish,
} from '../actions/job'
import { Table } from '../components/organisms'
import qs from 'qs'

const JobTrack = ({ location, jobsTrackState, jobsTrackDispatch }) => {
  const [ selectedJob, setSelectedJob ] = React.useState(undefined)
  const [ activeKey, setActiveKey ] = React.useState('0')

  useEffect(() => {
    if (jobsTrackState.jobsTrack.length === 0) {
      fetchJobTrackData(jobsTrackDispatch)
    }
    const query = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (get(query, 'publish')) {
      if (query.publish === 'true') {
        setActiveKey('0')
      }
      if (query.publish === 'false') {
        setActiveKey('1')
        fetchJobTrackUnPublish(jobsTrackDispatch)
      }
    }
  }, [])

  const getUsersInJobsTrack = jobId => {
    const { usersInJobsTrack } = jobsTrackState
    if (usersInJobsTrack.find(data => data.id === jobId)) {
      return Promise.resolve()
    } else {
      return fetchUserInJob(jobsTrackDispatch, jobId)
    }
  }

  return (
    <Block>
      <Header/>
      {selectedJob && (
        <JobAppliedModal
          job={jobsTrackState.jobsTrack.find(job => job.id === selectedJob.id)}
          onClose={() => {
            setSelectedJob(undefined)
          }}
          usersInJobsTrack={jobsTrackState.usersInJobsTrack}
          jobsTrackDispatch={jobsTrackDispatch}
        />
      )}
      <Block padding={'40px'}>
        <Block
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          marginBottom={'8px'}
        >
          <H5 margin={0}>งานทั้งหมด</H5>
          <Button onClick={() => navigate('/job-form')}>เพิ่มงานใหม่</Button>
        </Block>
        <Tabs
          onChange={({ activeKey }) => {
            setActiveKey(activeKey)
            if (
              activeKey === '1' &&
              jobsTrackState.unPublishJobs.length === 0
            ) {
              fetchJobTrackUnPublish(jobsTrackDispatch)
            }
          }}
          overrides={{
            TabBar: {
              style: {
                paddingLeft: '0px',
                paddingRight: '0px',
                height: '48px'
              },
            },
            TabContent: {
              style: {
                paddingLeft: '0px',
                paddingRight: '0px'
              }
            }
          }}
          activeKey={activeKey}
        >
          <Tab
            title="ประกาศแล้ว"
            overrides={{
              Tab: {
                style: ({
                          $theme,
                          $active,
                        }) => {
                  return {
                    ':focus': { outlineWidth: '0px' },
                    color: $active ? $theme.colors.primary : $theme.tabColor
                  }
                }
              }
            }}
          >
            <Table
              jobs={jobsTrackState.jobsTrack}
              total={jobsTrackState.total}
              updateData={limit =>
                fetchJobTrackData(
                  jobsTrackDispatch,
                  jobsTrackState.jobsTrack.length,
                  limit
                )
              }
              getUsersInJobsTrack={getUsersInJobsTrack}
              setSelectedJob={setSelectedJob}
              isPublic
            />
          </Tab>
          <Tab
            title="ยังไม่ประกาศ"
            overrides={{
              Tab: {
                style: ({
                          $theme,
                          $active,
                        }) => {
                  return {
                    ':focus': { outlineWidth: '0px' },
                    color: $active ? $theme.colors.primary : $theme.tabColor
                  }
                }
              }
            }}
          >
            <Table
              jobs={jobsTrackState.unPublishJobs}
              total={jobsTrackState.unPublishJobTotal}
              updateData={limit =>
                fetchJobTrackUnPublish(
                  jobsTrackDispatch,
                  jobsTrackState.unPublishJobs.length,
                  limit
                )
              }
              getUsersInJobsTrack={() => {
              }}
              setSelectedJob={setSelectedJob}
            />
          </Tab>
        </Tabs>
      </Block>
    </Block>
  )
}

export default withUser(withJobTrack(checkPermission([ 'professor' ], JobTrack)))
